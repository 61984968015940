import React from 'react';
import ContactForm from './contact/ContactForm';
import Cancel from '../../assets/icons/cancel.svg';

const ContactModal = ({ selectedService }) => {
  return (
    <div className='contact-modal slide-down'>
      <div className='header'>
        <div className='close' onClick={() => (window.location.hash = '')}>
          <Cancel width='35px' />
        </div>
      </div>
      <ContactForm light selectedService={selectedService} />
      <style jsx>{`
        .contact-modal {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          z-index: 1009;
          overflow-y: auto;
        }

        .header {
          display: flex;
          flex-direction: row-reverse;
          background-color: var(--background-white);
          padding: 16px;
        }

        .close {
          outline: none;
          cursor: pointer;
          margin-right: 10px;
        }

        .slide-down {
          animation-name: slideDown;
          animation-duration: 1s;
          animation-timing-function: ease;

          visibility: visible !important;
        }

        @keyframes slideDown {
          0% {
            transform: translateY(-100%);
          }
          100% {
            transform: translateY(0%);
          }
        }
      `}</style>
    </div>
  );
};

export default ContactModal;
