import React, { useEffect, useState } from 'react';
import Link from './Link';
import Logo from '../../assets/images/positive-zero-logo/logo.svg';
import Phone from '../../assets/icons/phone.svg';
import Email from '../../assets/icons/email.svg';
import Location from '../../assets/icons/location.svg';
import Facebook from '../../assets/icons/facebook.svg';
import Twitter from '../../assets/icons/twitter.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Linkedin from '../../assets/icons/linkedin.svg';
import { useTranslation } from 'react-i18next';

const linkMargin = '12px';

const Footer = props => {
  const { t, i18n } = useTranslation();
  const [currentPath, setCurrentPath] = useState('');

  // This effect gets the path from the url without the language
  useEffect(() => {
    const pathLang = i18n.languages.filter(lang => window.location.pathname.includes(`/${lang}`))[0];
    if (pathLang === 'en') {
      setCurrentPath(window.location.pathname.slice(3));
    } else {
      setCurrentPath(window.location.pathname);
    }
  }, []);

  return (
    <>
      <div className='wrapper global-padding global-margin'>
        <div className='col-icons'>
          <Logo />
          <div className='social-links'>
            <a aria-label='Facebook' target='_blank' rel='noopener noreferrer' href='http://fb.com/leavetheordinary'>
              <Facebook />
            </a>
            <a aria-label='Twitter' target='_blank' rel='noopener noreferrer' href='http://twitter.com/wearepositive0'>
              <Twitter />
            </a>
            <a
              aria-label='Instagram'
              target='_blank'
              rel='noopener noreferrer'
              href='http://www.instagram.com/wearepositivezero'
            >
              <Instagram />
            </a>
            <a
              aria-label='LinkedIn'
              target='_blank'
              rel='noopener noreferrer'
              href='http://www.linkedin.com/company/wearepositivezero'
            >
              <Linkedin />
            </a>
          </div>
          <div className='language'>
            <a href={`/en${currentPath}`}>
              <span className={i18n.language === 'en' ? 'language-current' : 'language-text'}>EN</span>
            </a>
            <div className='divisor'></div>
            <a href={currentPath}>
              <span className={i18n.language === 'es' ? 'language-current' : 'language-text'}>ES</span>
            </a>
          </div>
        </div>
        <div className='link-list'>
          <span className='col-title'>
            <Link style={{ marginBottom: linkMargin }} to='/contact'>
              {t('footer.contact')}
            </Link>
          </span>
          <ContactLink icon={Email} link='mailto: contact@positive0.com' text='contact@positive0.com' />
          <ContactLink
            icon={Location}
            link='https://goo.gl/maps/RbuoLmCnJkvSoXo47'
            text={t('footer.location')}
            target='_blank'
          />
        </div>
        <div className='link-list'>
          <span className='col-title'>
            <Link style={{ marginBottom: linkMargin }} to='/services'>
              {t('footer.services')}
            </Link>
          </span>
          <Link style={{ marginBottom: linkMargin }} to='/services#mobile-apps'>
            {t('footer.mobileApps')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} to='/services#web-development'>
            {t('footer.webDevelopment')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} to='/services#cloud-services'>
            {t('footer.cloudServices')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} to='/services#custom-systems'>
            {t('footer.customSystems')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} to='/services#consulting'>
            {t('footer.consulting')}
          </Link>
        </div>
        <div className='link-list'>
          <span className='col-title'>{t('footer.work')}</span>
          <Link style={{ marginBottom: linkMargin }} to='/courses'>
            {t('footer.courses')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} to='/methodology'>
            {t('footer.methodology')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} to='/technologies'>
            {t('footer.technologies')}
          </Link>
        </div>
        <div className='link-list'>
          <span className='col-title'>{t('footer.company')}</span>
          <Link style={{ marginBottom: linkMargin }} to='/join-us'>
            {t('footer.joinUs')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} className='link' to='/about'>
            {t('footer.about')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} className='link' to='/projects'>
            {t('footer.projects')}
          </Link>
          <Link style={{ marginBottom: linkMargin }} className='link' to='/terms-and-conditions'>
            {t('footer.terms-and-conditions')}
          </Link>
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
          margin-top: 100px;
        }

        .col-title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 1em;
        }

        .link {
          list-style: none;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .col-icons {
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;
          margin-left: 10px;
          margin-right: 10px;
        }

        .social-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 50px;
        }

        .link-list {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 20px;
          list-style-type: none;
        }

        .language {
          display: flex;
          margin-top: 30px;
          font-weight: 600;
          font-size: 18px;
        }

        .language-text {
          color: #8c8f90;
          cursor: pointer;
        }

        .language-current {
          color: #fff;
          cursor: pointer;
        }

        .divisor {
          width: 2px;
          background-color: #717476;
          margin-left: 10px;
          margin-right: 10px;
        }

        @media only screen and (max-width: 500px) {
          .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            flex-wrap: wrap;
            margin-top: 100px;
            padding: 50px;
          }
        }
      `}</style>
    </>
  );
};

const ContactLink = ({ icon, link, text, target }) => {
  const Icon = icon;
  return (
    <>
      <a href={link} target={target} rel='noopener noreferrer'>
        <div className='contact-link'>
          <Icon width='1.3em' height='1.3em' />
          <div className='text'>{text}</div>
        </div>
      </a>

      <style jsx>{`
        .contact-link {
          display: flex;
          align-items: center;
          margin-bottom: ${linkMargin};
        }

        .text {
          list-style: none;
          font-size: 16px;
          margin-left: 13px;
        }
      `}</style>
    </>
  );
};

export default Footer;
