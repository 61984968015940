import React from 'react';
import Link from './Link';

const NavBarLink = props => {
  let { style, children, to, selected } = props;
  return (
    <>
      <Link to={to} style={{ color: selected ? '#FFA500' : '#FFF' }}>
        <div className='navbarLink'>{children}</div>
      </Link>
      <style jsx>{`
        .navbarLink {
          padding-left: 25px;
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          display: flex;
          align-items: center;
          text-decoration: none;
        }
        /* Para resoluciones móviles */
        @media only screen and (max-width: 876px) {
          .navbarItems,
          .navbar {
            flex-direction: column;
          }

          .navbarItems {
            display: none;
          }

          .navbarItemsRight {
            margin-left: 0;
          }

          .navbarLinkToggle {
            display: flex;
            cursor: pointer;
            align-items: center;
            vertical-align: center;
          }

          .navbarToggleShow {
            display: flex;
          }

          .navbarLink {
            padding-left: 0;
            padding: 20px 0;
            animation: slideDown 0.2s forwards;
          }
        }
      `}</style>
    </>
  );
};

export default NavBarLink;
