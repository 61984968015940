import Link from './Link';
import PropTypes from 'prop-types';
import React from 'react';
import NavBarLink from './NavBarLink';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../../assets/images/positive-zero-logo/logo.svg';
import Logo0 from '../../assets/images/positive-zero-logo/icon.svg';

import { useTranslation } from 'react-i18next';

const NavBar = props => {
  const { t } = useTranslation();
  const navClassName = props.secondary ? 'navbar-items-secondary' : 'navbar-items';

  return (
    <>
      <div className={props.secondary ? 'navbar-secondary global-slide-down' : 'navbar'}>
        <div className='inner-nav global-margin'>
          <div
            style={{
              zIndex: 100,
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Link aria-label='logo' to='/'>
              <div className='logoContainer'>{props.secondary ? <Logo0 width='40px' height='40px' /> : <Logo />}</div>
            </Link>

            <div className='navbarLinkToggle' onClick={() => props.onClick()}>
              {props.open ? <FaTimes size='1.5em' /> : <FaBars size='1.5em' />}
            </div>
          </div>

          <div className={`navbarItems navbarItemsRight ${props.open && navClassName}`}>
            <NavBarLink to='/' selected={props.section == 'HOME'}>
              {t('navbar.home')}
            </NavBarLink>
            <NavBarLink to='/services' selected={props.section == 'SERVICES'}>
              {t('navbar.services')}
            </NavBarLink>
            <NavBarLink to='/projects' selected={props.section == 'PROJECTS'}>
              {t('navbar.projects')}
            </NavBarLink>
            <NavBarLink to='/about' selected={props.section == 'ABOUT'}>
              {t('navbar.about')}
            </NavBarLink>
            <NavBarLink to='/contact' selected={props.section == 'CONTACT'}>
              {t('navbar.contact')}
            </NavBarLink>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .inner-nav {
            display: flex;
            flex-direction: row;
            align-self: center;
          }

          .navbar {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            margin: 1.2rem auto;
            position: relative;
            top: 0px;
            background-color: var(--primary-dark);
            z-index: 999;
            transition: all 0.25s ease-out;
          }

          .navbar-secondary {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            justify-content: flex-start;
            align-self: center;
            margin: 1.2rem auto;
            position: sticky;
            top: 0px;
            background-color: #262626;
            background-color: #262626f2;
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
            z-index: 999;
            /* transition: all 1s ease-out; */
          }

          .logoContainer {
            display: flex;
          }

          .navbarItems {
            display: flex;
            align-items: center;
          }

          .navbarItemsRight {
            margin-left: auto;
          }

          .navbarLinkToggle {
            display: none;
          }

          .navbarLink:hover {
            color: #b9b9b9;
            transform: translateY(-2px);
            transition: 0.2s;
          }
          /* Para resoluciones móviles */
          @media only screen and (max-width: 876px) {
            .inner-nav {
              display: block;
              align-self: center;
            }

            .navbarItems,
            .navbar-secondary,
            .navbar {
              flex-direction: column;
            }

            .navbarItems {
              display: none;
            }

            .navbarItemsBase {
              position: fixed;
            }

            .navbarItemsRight {
              margin-left: 0;
            }

            .navbarLinkToggle {
              display: flex;
              cursor: pointer;
              align-items: center;
              vertical-align: center;
            }

            .navbar-items {
              display: flex;
              position: absolute;
              background-color: #262626f2;
              width: 100%;
              left: 0;
            }

            .navbar-items-secondary {
              display: flex;
            }

            .navbarLink {
              padding-left: 0;
              padding-top: 10px;
              animation: slideDown 0.2s forwards;
            }
          }
        `}
      </style>
    </>
  );
};

NavBar.propTypes = {
  siteTitle: PropTypes.string
};

NavBar.defaultProps = {
  siteTitle: ``
};

export default NavBar;
