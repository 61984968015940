import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

const ExtendedLink = props => {
  const { to, ...linkProps } = props;
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <Link to={`${language == 'es' ? '' : '/' + language}${to}`} {...linkProps}>
      {props.children}
    </Link>
  );
};

export default ExtendedLink;
