import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/images/positive-zero-logo/icon.svg';
import IconBlack from '../../../assets/images/positive-zero-logo/icon-black.svg';
import Select from '../../components/fields/Select';
import Phone from '../../../assets/icons/phone.svg';
import Email from '../../../assets/icons/email.svg';
import { useTranslation } from 'react-i18next';

const ContactForm = ({ light }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [moreInfo, setMoreInfo] = useState('');
  const [projectType, setProjectType] = useState('Web development');
  const [source, setSource] = useState('Social networks');
  const [btnText, setBtnText] = useState(t('contact.sendButton'));
  const [submitted, setSubmitted] = useState(false);
  console.log(t('contact.sendButton'));
  const contactBtnThanksText = t('contact.thanksMessage');
  console.log(contactBtnThanksText);

  useEffect(() => {
    if (window.location.hash.includes('%3E')) {
      let service = window.location.hash.split('%3E')[1];
      //TODO: accept accents. E. g. Apps m%C3%B3viles
      service = service.split('%20').join(' ');
      setProjectType(service);
    }
    return () => {};
  }, []);

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const submit = e => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        name,
        email,
        'project-type': projectType,
        'project-info': moreInfo,
        source
      })
    })
      .then(() => {
        setName('');
        setEmail('');
        setMoreInfo('');
        // setBtnText(contactBtnThanksText);
        setSubmitted(true);
      })
      .catch(error => alert(error));
  };

  return (
    <>
      <div className={`content ${light && 'global-light'}`}>
        {!submitted && (
          <form className='inputs' method='post' onSubmit={e => submit(e)}>
            <input
              required
              type='text'
              name='name'
              placeholder={t('contact.form.namePlaceHolder')}
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              required
              type='email'
              name='email'
              placeholder={t('contact.form.emailPlaceHolder')}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Select
              label={t('contact.selectProjects.label')}
              name='project-type'
              options={[
                t('contact.selectProjects.options.webDevelopment'),
                t('contact.selectProjects.options.mobileApps'),
                t('contact.selectProjects.options.customSystems'),
                t('contact.selectProjects.options.cloudServices'),
                t('contact.selectProjects.options.consulting'),
                t('contact.selectProjects.options.courses'),
                t('contact.selectProjects.options.other')
              ]}
              value={projectType}
              onChange={value => setProjectType(value)}
              light={light}
            />
            <textarea
              id='more-info'
              name='project-info'
              placeholder={t('contact.textAreaPlaceHolder')}
              rows='4'
              value={moreInfo}
              onChange={e => setMoreInfo(e.target.value)}
            />
            <Select
              label={t('contact.howDidYouHear.label')}
              name='source'
              options={[
                t('contact.howDidYouHear.options.socialNetworks'),
                t('contact.howDidYouHear.options.friends'),
                t('contact.howDidYouHear.options.ads'),
                t('contact.howDidYouHear.options.other')
              ]}
              value={source}
              onChange={value => setSource(value)}
              light={light}
            />
            <button type='submit'>{btnText}</button>
          </form>
        )}
        <div className='contact-info thanks'>
          {submitted && (
            <div className='thanks'>
              <h1>{t('contact.thanks.header')}</h1>
              <p style={{ color: 'var(--primary-color)' }}>{t('contact.thanks.text')}</p>
            </div>
          )}
          {light ? <IconBlack height='20vh' width='20vw' /> : <Icon height='20vh' width='20vw' />}

          <div className='text'>
            <h3>{t('contact.contactInfo')}</h3>
            <ContactLink icon={Email} link='mailto:contact@positive0.com' text='contact@positive0.com' />
          </div>
        </div>
      </div>

      <style jsx>{`
        h1, h3 {
          color: inherit;
        }

        textarea {
          resize: vertical;
        }

        .thanks {
          animation-name: slideToLeft;
          animation-duration: 1s;
          animation-timing-function: ease;
          visibility: visible !important;
          text-align: center;
        }

        @keyframes slideToLeft {
          0% {
            transform: translateX(80%);
          }
          100% {
            transform: translateX(0%);
          }
        }

        .content {
          display: flex;
          min-height: calc(100% - 73.4px);
        }

        .content > * {
          margin: 40px;
        }

        .inputs {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }

        .contact-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
        }

        .text {
          text-align: center;
        }

        input:active,
        input:focus,
        textarea:active,
        textarea:focus {
          border-color: ${light} ? black : var(--background-white);
        }

        @media only screen and (max-width: 900px) {
          .content {
            flex-direction: column;
          }
        }
      `}</style>
    </>
  );
};

const ContactLink = ({ icon, link, text }) => {
  const Icon = icon;
  return (
    <>
      <a href={link}>
        <div className='contact-link'>
          <Icon width='1.3em' height='1.3em' />
          <p>{text}</p>
        </div>
      </a>

      <style jsx>{`
        .contact-link {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
        }

        p {
          list-style: none;
          margin: 0;
          margin-left: 15px;
        }
      `}</style>
    </>
  );
};

export default ContactForm;
