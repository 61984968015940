import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import NavBar from './NavBar';
import Footer from './Footer';
import ContactModal from './ContactModal';

const Layout = ({ children, section, manualMargin, manualPadding, noFooter }) => {
  const [scrolled, setScrolled] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [secondaryNavbarOpen, setSecondaryNavbarOpen] = useState(false);

  useEffect(() => {
    const onHashChange = () => {
      setShowContact(window.location.hash.includes('#contact'));
    };

    onHashChange();
    window.addEventListener('hashchange', onHashChange, false);

    return () => {
      window.removeEventListener('hashchange', onHashChange, false);
    };
  }, []);

  useEffect(() => {
    const updateNavbar = () => {
      const isScrolled = window.scrollY > 600;

      if (scrolled != isScrolled) {
        setScrolled(isScrolled);
      }

      // Close primary navbar when scrolled
      if (isScrolled && navbarOpen) {
        setNavbarOpen(false);
      }
    };
    window.addEventListener('scroll', updateNavbar);
    return () => {
      window.removeEventListener('scroll', updateNavbar);
    };
  }, [navbarOpen, scrolled]);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          {showContact && <ContactModal />}
          <NavBar
            siteTitle={data.site.siteMetadata.title}
            section={section}
            open={navbarOpen}
            onClick={() => setNavbarOpen(!navbarOpen)}
          />
          {scrolled && (
            <NavBar
              siteTitle={data.site.siteMetadata.title}
              section={section}
              secondary
              open={secondaryNavbarOpen}
              onClick={() => setSecondaryNavbarOpen(!secondaryNavbarOpen)}
            />
          )}
          <div
            className={(!manualMargin && 'global-margin ') + (!manualPadding && 'global-padding')}
            style={{
              margin: `0 auto`,
              paddingTop: 0
            }}
          >
            <main>{children}</main>
            {!noFooter && <Footer />}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
