import React from 'react';

const Select = props => {
  let backgroundColor = props.light ? 'var(--background-white)' : 'var(--primary-black)';
  return (
    <>
      <div className='select-container'>
        <label htmlFor={props.name}>{props.label}</label>
        <select
          id={props.name}
          name={props.name}
          className='select-css'
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        >
          {props.options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <style jsx>{`
        .select-container {
          margin-bottom: 20px;
        }

        .select-css {
          font-family: 'Poppins', sans-serif;
          margin: 5px 0;
          max-width: 250px;
          display: block;
          font-size: 16px;
          font-weight: 700;
          color: inherit;
          line-height: 1.3;
          padding: 0.6em 1.4em 0.5em 0.8em;
          width: 100%;
          background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffa500%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
          box-sizing: border-box;
          border: 1px solid gray;
          box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
          border-radius: 0.5em;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-color: ${backgroundColor};
          background-repeat: no-repeat, repeat;
          background-position: right 0.7em top 50%, 0 0;
          background-size: 0.65em auto, 100%;
        }

        .select-css::-ms-expand {
          display: none;
        }

        .select-css:hover {
          border-color: #ffa500;
        }

        .select-css:focus {
          box-shadow: 0 0 1px 3px rgba(255, 166, 0, 0.3);
          box-shadow: 0 0 0 3px -moz-mac-focusring;
          outline: none;
        }

        .select-css option {
          font-size: 12px;
        }
      `}</style>
    </>
  );
};

export default Select;
